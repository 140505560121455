import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import "./styles/color.css";
import "./styles/font.css";
import "./styles/index.css";
import "./styles/tailwind.css";
ReactDOM.render(
    <App />,
    document.getElementById("root"),
)